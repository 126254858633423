/***************************************************************
 * @NOTE: 医療機関詳細暫定移植で追加したファイルのため正規対応時に整理予定
 ***************************************************************/
/* eslint no-undef: 0 */
$(function () {
  // もっと見るボタン表示可否
  $('.js-more-display-block').each(function (_idx, elm) {
    moreDisplay(elm);
  });

  // よかレポの `続きをみる` の表示可否
  // 文章要素の高さが6.6rem(c-yokarepo-body__txtが閉じている時のmax-height指定値)
  // 以上の場合、続きを見るを表示
  // 以下の場合、続きを見るを非表示
  const targetHeight = convertRemToPx(6.6);
  $('.js-toggle-show-more').each(function (_idx, elm) {
    // 文章要素の高さが6.6rem(c-yokarepo-body__txtが閉じている時のmax-height指定値)
    // 以上の場合、続きを見るを表示して、onClickイベントをセットする
    // 以下の場合、続きを見るを非表示(デフォルト)のままにする
    const $elm = $(elm);
    const $textElement = $elm.siblings('.js-show-more-text');

    if ($textElement.outerHeight() > targetHeight) {
      $textElement.removeClass('is-active');
      $elm.removeClass('u-hide');
      $elm.on('click', new ToggleShowMore(elm));
    }
  });

  // 医療機関に紐付く概要情報の表示エリア
  const MORE_TEXT_BASE_HEIGHT = 72;
  bindHospitalDetailExpandButton(MORE_TEXT_BASE_HEIGHT);
});

// もっと見るボタン表示制御
function moreDisplay (parentElement) {
  const $parentElement = $(parentElement);
  const $items = $($parentElement.find('.js-more-display-item'));
  const $displayMoreButton = $($parentElement.parent().find('.js-more-display-button:first'));
  const limitSize = $parentElement.data('limit-size');

  if ($items.length > limitSize) {
    $items.slice(limitSize).hide();
    $displayMoreButton.show();
    $displayMoreButton.on('click', function () {
      const parent = findParentElement(this, '.js-more-display-block', 1, 3);
      if (parent !== null) {
        $(parent).find('.js-more-display-item').show();
        $(this).hide();
      }
    });
  } else {
    $displayMoreButton.hide();
  }
}

// baseElementの祖先の子孫要素からfindClassで指定されたクラスを持つ要素を探して返す
// maxCount回数分遡って見つからなければnullを返す
// baseElement: 起点となる要素
// findClass: 検索対象のClass名
// currentCount: 現在の繰り返し回数
// maxCount: 最大繰り返し回数
function findParentElement (baseElement, findClass, currentCount, maxCount) {
  if (currentCount >= maxCount) {
    return null;
  }

  const $parent = $(baseElement).parent();
  const findElement = $parent.find(findClass);

  if (findElement.get(0)) {
    return findElement.get(0);
  }

  return findParentElement($parent.get(0), findClass, currentCount + 1, maxCount);
}

// remをpxに変換する
function convertRemToPx (rem) {
  const rootFontSize = $(':root').css('font-size');
  return rem * parseFloat(rootFontSize);
}

// 続きをみる動作制御
const ToggleShowMore = function (element) {
  let toggle = false;
  const $element = $(element);

  return function () { // closure
    const willToggle = !toggle;

    if (willToggle) {
      $element.addClass('is-active');
      $element.find('.js-toggle-show-more-label').text('閉じる');
      $element.siblings('.js-show-more-text').addClass('is-active');
    } else {
      $element.removeClass('is-active');
      $element.find('.js-toggle-show-more-label').text('続きをみる');
      $element.siblings('.js-show-more-text').removeClass('is-active');
    }

    toggle = willToggle;
  };
};

function bindHospitalDetailExpandButton (baseHeight) {
  const descriptionHeight = $('.js-institution-detail-summary-info__description-txt').height();
  if (baseHeight >= descriptionHeight) {
    // 表示部が基準のheight未満の場合は「もっと見る」ボタンを非表示にしてheightを揃える
    $('.js-institution-detail-summary-info__description-expand').hide();
    $('.js-institution-detail-summary-info__description-txt-wrap').height(descriptionHeight);
    return;
  }

  const $descriptionWrap = $('.js-institution-detail-summary-info__description-txt-wrap');
  const $btnExpand = $('.js-institution-detail-summary-info__description-expand');
  const $btnExpandTxt = $('.js-institution-detail-summary-info__description-expand span > span');
  let isOpen = false;
  $btnExpand.on('click', function () {
    const positon = $(window).scrollTop();
    if (isOpen) {
      $(this).removeClass('is-open');
      $descriptionWrap.animate({ height: baseHeight });
      $btnExpandTxt.text('もっと見る');
    } else {
      $(this).addClass('is-open');
      $descriptionWrap.animate({ height: descriptionHeight });
      $btnExpandTxt.text('閉じる');
    }
    $('html').animate({ scrollTop: positon });
    isOpen = !isOpen;
  });
}
